// react / scss
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/signIn.scss';

// libraries
import axios from "axios";

// custom modules
import { serverAddress as proxy, includesAllOfMultipleStrings, filterText, numberOfCharactersInString, serverAddress } from '../custom-modules/customModules';

// Assets
import redExclamation from './../Assets/icons/red-exclamation-mark.png';
import hiddenIcon from './../Assets/icons/hidden.png';
import viewIcon from './../Assets/icons/view.png';
import loader from './../Assets/loader.gif';
import { redirect } from 'react-router-dom';


function SignIn() 
{
    // use state constants
    const [viewPassword, setViewPassword] = useState(false);
    const [errorLables, setErrorLabels] = useState({
        general: "",
        vcard: "",
        email: "",
        password: "",
        highestRole: ""
    });
    const [isSigning, setIsSigning] = useState(false);

    // use state inputs
    const [vcardInput, setVcardInput] = useState("");
    const [emailInput, setEmailInput] = useState("");
    const [passwordInput, setPasswordInput] = useState("");
    const [highestRoleInput, setHighestRoleInput] = useState("");

    // variables
    const bannedCharacters = ["#", "/", "*", "$", "^", "&", "(", ")", "+", "-", "!", "~", "`", " "];
    
    const redirect = useNavigate();

    // functions
    function setupVcardInput(value)
    {
        if (isNaN(Number(value)) || value.toString().includes(".")) return;
        setVcardInput(value.toString());
    }

    function setupEmailInput(value)
    {
        if (filterText(value, bannedCharacters) !== false && numberOfCharactersInString(value, "@") <= 1)
        {
            setEmailInput(filterText(value, bannedCharacters));
        }
    }

    function setupPasswordField(value)
    {
      if (filterText(value, ["'", '"', "`"]) !== false) setPasswordInput(filterText(value, ["'", '"', "`"]));
    }



    function signIn()
    {

        setIsSigning(true);
        let isValid = true;
        let errorLabelsTemp = {
            general: "",
            vcard: "",
            email: "",
            password: "",
            highestRole: ""
        };

        setErrorLabels(errorLabelsTemp);

        if (vcardInput.toString().length != 8)
        {
            errorLabelsTemp.vcard = "Your Virtual Card ID must be 8 letters!";
            isValid = false;
        }

        if (emailInput.toString().length == 0)
        {
            errorLabelsTemp.email = "Please enter your Email!";
            isValid = false;
        }

        if (!emailInput.toString().includes("@") || !emailInput.toString().includes("."))
        {
            errorLabelsTemp.email = "Email does not contain the necessary characters!";
            isValid = false;
        }

        if (passwordInput.toString().length == 0)
        {
            errorLabelsTemp.password = "Please enter your Password!";
            isValid = false;
        }

        if (highestRoleInput.toString().length == 0 || highestRoleInput.toString() == "---")
        {
            errorLabelsTemp.highestRole = "Please select a role!";
            isValid = false;
        }


        if (!isValid)
        {
            setErrorLabels(errorLabelsTemp);
            setIsSigning(false);
            return;
        }


        axios.post(`${proxy}/getAdminUserInfo`, { vcard_id: vcardInput, email: emailInput, password: passwordInput })
            .then((res) => 
                {
                    if (res.data == "err-0" || res.data.length > 1) errorLabelsTemp.general = "Something went wrong!";
                    else if (res.data.length == 0) errorLabelsTemp.general = "Email, Password, and the Virtual Card ID don't match!";
                    else
                    {
                        let data = res.data[0];
                        localStorage.setItem("admin-id", data.admin_id);
                        redirect("/");
                    }

                    setIsSigning(false);
                })
            .catch((err) => 
                {
                    console.error(err);
                    setErrorLabels({ ...errorLables, general: "Something went wrong!" });
                });
    }

    // JSX
  return (
    <div className="signin-container-container">
        <div className="signin-container">
            <div className="signin-warning panel">
                <img className="siginin-warning-img" src={redExclamation} style={{ width: "25px" }} />
                <strong style={{ margin: "5px", fontSize: "20px"  }}>Warning!</strong>
                <span>This is ORDIOUS' Admin Panel and it is only created for admins to moderate ORDIOUS services. If you are <strong>not an admin</strong> and trying to access the admin panel or you are using an admin's info to gain access to the website, your entrance and IP address will be logged and reported to us. If we find out that you entered illegally, your <strong>IP</strong> and your <strong>ORDIOUS Account</strong> plus <strong>every other separate account</strong> in any ORDIOUS service which does have its own account system, will be <strong>permanently banned.</strong></span> <br />
                <span>If you are not an admin, <strong>leave immediately.</strong></span>
            </div>
            <div className='singin-singin-panel panel'>
                <strong style={{ fontSize: "30px" }}>Admin Access Sign In</strong> <br />
                <span className="error">{errorLables.general}</span> <br /> <br /> <br />
                <div className="top-field-section">
                    <span>Virtual Card ID (without #)</span>
                </div>
                <div className="field"><strong>#</strong><input className="input-field" type="text" placeholder="12345678" value={vcardInput} onInput={(e) => setupVcardInput(e.target.value)} maxLength={8} /></div>
                <div className="text-align-left"><span className="error">{errorLables.vcard}</span></div> <br /> 

                <div className="top-field-section">
                    <span>ORDIOUS Account Email</span>
                </div>
                <div className="field"><input className="input-field" type="text" placeholder="example@ordious.com" value={emailInput} onInput={(e) => setupEmailInput(e.target.value)} /></div> 
                <div className="text-align-left"><span className="error">{errorLables.email}</span></div> <br />

                <div className="top-field-section">
                    <span>ORDIOUS Account Password</span>
                </div>
                <div className="field">
                    <input className="input-field" type={(viewPassword) ? "text" : "password"} value={passwordInput} onInput={(e) => setupPasswordField(e.target.value)} />
                    <button onClick={() => setViewPassword(!viewPassword)} style={{ cursor: "pointer", background: "none", border: "none" }}>
                        <img src={(viewPassword) ? viewIcon : hiddenIcon} style={{ width: "22px" }} />
                    </button>
                </div>
                <div className="text-align-left"><span className="error">{errorLables.password}</span></div> <br />

                <div className="top-field-section">
                    <span>What is your highest role on ORDIOUS? (Your answer will be logged.)</span>
                </div>
                <select className="dropdown" onChange={(e) => setHighestRoleInput(e.target.value)}>
                    <option selected>---</option>
                    <option>Admin Rank 1</option>
                    <option>Admin Rank 2</option>
                    <option>Admin Rank 3</option>
                    <option>Admin Rank 4 (Moderator Rank 1)</option>
                    <option>Admin Rank 5 (Moderator Rank 2)</option>
                </select>
                <div className="text-align-left"><span className="error">{errorLables.highestRole}</span></div> <br /> <br />
                
                {
                    (isSigning) ?
                        <img src={loader} style={{ width: "60px" }} />
                        :
                        <button className="btn" onClick={signIn}><strong>Enter Admin Panel</strong></button>
                }
            </div> <br /> <br />
        </div>
    </div>
  );
}

export default SignIn;