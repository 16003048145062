import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/memedify-userbase.scss';
import Loading from '../../custom-components/Loading';

import loader from '../../Assets/loader.gif';

import axios from 'axios';
import { serverAddress as proxy, serverAddressMemedify as proxyMd, splitByCharacter, convertDate } from '../../custom-modules/customModules';


function MemedifyUserbase() {

  const [isLoaded, setIsLoaded] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [latestRefreshDate, setLatestRefreshDate] = useState("");
  const [banningId, setBanningId] = useState("019b6d85-13e5-47e1-87ea-3015538c0bbd");

  const date = new Date();


  const redirect = useNavigate();

  // useEffect(() => 
  // {
  
  // }, []);


  if (!isLoaded)
  {
    if (localStorage.getItem("admin-id") != null && localStorage.getItem("admin-id") != "")
    {
      axios.post(`${proxyMd}/getAdminUserData`)
        .then((res) => 
        {
          let data = res.data;
          setUsersData(data);
          setIsLoaded(true);
          let dateInfo = `${convertDate(`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`)} - ${(date.getHours().toString().length == 1) ? `0${date.getHours()}` : date.getHours()}:${(date.getMinutes().toString().length == 1) ? `0${date.getMinutes()}` : date.getMinutes()}`;
          setLatestRefreshDate(dateInfo);
        })
        .catch((err) => console.error(err));
    }
    else redirect("/signin");
          
  }


  function banUser(id)
  {
    if (banningId != null) return;
    setBanningId(id);



  }

  if (!isLoaded) return <Loading />;

  return (
    <div style={{ margin: "8px" }}>
      <h2>Memedify's Userbase</h2>
      <span>Last update: {latestRefreshDate} (Frankfurt, Germany [Server's Location])</span> <br /> <br /> <br />
      <table className="md-userbase-table">
        <tr>
          <th>#</th>
          <th>User ID</th>
          <th>Username</th>
          <th>Email</th>
          <th>Display Name</th>
          <th>Date Of Creation</th>
          <th>Status</th>
          {/* <th>Ban User</th> */}
        </tr>
        
        {
          usersData.map((value, index) =>
          <tr>
            <td>{value.id}</td>
            <td>{value.user_id}</td>
            <td>{value.username}</td>
            <td>{value.email}</td>
            <td>{(value.display_name == "" || value.display_name == null) ? "-" : value.display_name}</td>
            <td>{`${splitByCharacter(value.date_of_creation.toString().substring(0, 10).replaceAll("-","/"), "/")[2]}/${splitByCharacter(value.date_of_creation.toString().substring(0, 10).replaceAll("-","/"), "/")[1]}/${splitByCharacter(value.date_of_creation.toString().substring(0, 10).replaceAll("-","/"), "/")[0]} (${convertDate(value.date_of_creation.toString().substring(0, 10).replaceAll("-","/").toString(), true)}) `}</td>
            <td>{value.status}</td>
            {/* <td>
              {
                (banningId == value.user_id) ?
                  <div style={{ textAlign: "center" }}><img src={loader} style={{ width: "40px" }} /></div>
                  :
                  <button className="ban-btn">Ban</button>
              }
            </td> */}
          </tr>)

        }
      </table> 
        <ul className="note-list">
          Notes:
          <li>This is the entire Memedify userbase. You are resposible for the listed users' privacy policy. You must not share these information with an untrusted source, you must not use this data illegally (i.e. scamming), and you must not modify the data with no valid reason.</li>
          <li>You can ban any user. But any other moderator could later unban them.</li>
          <li>No user data can be deleted. You can only restrict the user's access to their own data.</li>
          <li>The Status shows if the account is enabled to access the provided features. If the value is any other than <strong>accepted</strong>, the user does not have full access to the provided features.</li>
          <li>If you recognize that something is wrong with this data and you do not have full access to fix the issue, please let a higher-level admin know about the situation.</li>
        </ul>
    </div>
  );
}

export default MemedifyUserbase;