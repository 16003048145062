import React, { useState } from 'react';
import Loading from '../../custom-components/Loading';
import { useNavigate } from 'react-router-dom';

import { serverAddress as proxy, serverAddressMemedify as proxyMd } from '../../custom-modules/customModules';

import axios from 'axios';

import loaderGif from '../../Assets/loader.gif';
import SmartPfp from '../../custom-components/SmartPfp';

function MemedifyFindUserById() 
{
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [userId, setUserId] = useState("");
    const [userInfo, setUserInfo] = useState({
        user_id: 0,
        display_name: "",
        username: "",
        date_of_creation: "",
        status: "-",
        follows: 0
    });
    const [loadError, setLoadError] = useState("");

    const redirect = useNavigate();


    if (!isLoaded)
    {
        if (localStorage.getItem("admin-id") != null && localStorage.getItem("admin-id") != "")
        {
            setIsLoaded(true);
        }
        else redirect("/signin");
    }

    function search()
    {
        let isValid = true;
        // setUserInfo(null);
        setLoadError("");
        setIsSearching(true);
        if (userId.length < 36 || userId.length > 36)
        {
            setLoadError("ID is too short! (or too long)");
            isValid = false;
        }

        if (userId.includes("'") || userId.includes("`"))
        {
            setLoadError("Invalid characters!");
            isValid = false;
        }


        if (!isValid) 
        {
            setIsSearching(false);
            return;
        }

        axios.post(`${proxyMd}/getUserById`, { id: userId })
            .then((res) => 
            {
                setIsSearching(false);
                if (res.data.length == 0)
                {
                    setLoadError("This user does not exist!");
                    return
                }

                let data =  res.data[0];
                setUserInfo(data);   
            })
            .catch((err) => console.error(err));
    }


    if (!isLoaded) return <Loading />;

  return (
    <div style={{ margin: "8px" }}>
        <div className="panel">
            <h3>Find user by User Id</h3>
            <span>User ID: </span>
            <div className="field"><input type="text" className="input-field" style={{ textAlign: "center" }} value={userId} onInput={(e) => setUserId(e.target.value)} maxLength={36} /></div> <br />
            <span className="error">{loadError}</span> <br /> <br />
            { (isSearching) ? <img src={loaderGif} style={{ width: "60px" }} /> : <button className="btn" onClick={search}>Find</button> }
        </div> <br />
        <div className="panel" style={(userInfo.user_id == 0) ? { display: "none" } : { textAlign: "left", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
                <SmartPfp id={userInfo.user_id} /> <br />
                {(userInfo.display_name != "") ? <><strong>{userInfo.display_name}</strong> <br /></> : <></>}
                <span>{userInfo.username}</span> <br />
                <span style={{ color: "gray" }}>{userInfo.date_of_creation}</span> <br />
            </div>
            <div style={{ textAlign: "right" }}>
                <span><strong>{userInfo.follows}</strong> followers</span> <br />
                <strong>Account Status: <span style={(userInfo.status == "accepted") ? { color: "green" } : { color: "red" }}>{userInfo.status}</span></strong>
            </div>
        </div>
    </div>
  );
}

export default MemedifyFindUserById;