import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import './styles/layout.scss';
import Loading from '../custom-components/Loading';

import axios from 'axios';
import { serverAddress as proxy } from '../custom-modules/customModules';

import ordiousAdminPanelLogo1 from '../Assets/ordious-admin-panel-logo1.png';


function Layout() 
{
  const redirect = useNavigate();

  const [isLoaded, setIsLoaded] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => 
    {
      let fullPathname = window.location.href;
      if (fullPathname.slice(0, 7) == "http://")
      {
        window.location.replace(`https://admin.ordious.com`);
      }
    }, []);


  useEffect(() => 
  {
    if (!isLoaded && localStorage.getItem("admin_id") != null && localStorage.getItem("admin_id") != "")
    {
      axios.post(`${proxy}/getAdminInfoById` , { id: localStorage.getItem("admin_id") })
        .then((res) => 
        {
          let data = res.data[0];
          setUser(data);
          setIsLoaded(true);
          setIsLoggedIn(true);
        })
        .catch((err) => console.error(err));
    }
    else if (!isLoaded && (localStorage.getItem("admin_id") == null || localStorage.getItem("admin_id") == "")) 
    {
      redirect("/signin");
      setIsLoaded(true);
      setIsLoggedIn(false);
    }

    if (localStorage.getItem("admin_id") != null && localStorage.getItem("admin_id") != "" && !isLoggedIn) setIsLoggedIn(true)
  }, []);


  


  if (!isLoaded) return <Loading />;
  // if (!isLoggedIn) return <Outlet />;

  return (
    <div>
      <nav className="navigation-bar-container">
        <div className="navigation-bar">
          <div className="navigation-left">
            <div className="logo-container" style={{ cursor: "pointer" }} onClick={() => redirect("/")}>
              <img src={ordiousAdminPanelLogo1} style={{ height: "35px" }} />
              {/* <label style={{ fontSize: "20px" }}>by <strong>PopularSun</strong></label> */}
            </div>
          </div>
          <div className="navigation-right"></div>
        </div>
      </nav>
      <Outlet />
    </div>
  );
}

export default Layout;