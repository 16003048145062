import React, { useState, useEffect } from 'react';
import { redirect, useNavigate } from 'react-router-dom';

import Loading from '../../custom-components/Loading';
import loaderGif from '../../Assets/loader.gif';
import axios from 'axios';
import { serverAddress as proxy, serverAddressMemedify as proxyMd, convertDate, splitByCharacter } from '../../custom-modules/customModules';

function MemedifyBans() 
{
    const [isLoaded, setIsLoaded] = useState(false);
    const [bans, setBans] = useState([]);
    const [latestRefreshDate, setLatestRefreshDate] = useState("");
    const [unabnningId, setUnbanningId] = useState(null);

    const date = new Date();



    if (!isLoaded)
    {
        if (localStorage.getItem("admin-id") != null && localStorage.getItem("admin-id") != "")
        {
            axios.post(`${proxyMd}/getBans`)
            .then((res) => 
            {
                let data = res.data;
                setBans(data);
                let dateInfo = `${convertDate(`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`)} - ${(date.getHours().toString().length == 1) ? `0${date.getHours()}` : date.getHours()}:${(date.getMinutes().toString().length == 1) ? `0${date.getMinutes()}` : date.getMinutes()}`;
                setLatestRefreshDate(dateInfo);
                setIsLoaded(true);
            })
            .catch((err) => console.error(err));
        }
        else redirect("/signin");
    }

    function unbanUser(id, banId)
    {
        if (unabnningId != null) return;
        setUnbanningId(banId);

        axios.post(`${proxyMd}/unbanUserById`, { id: id })
            .then((res) => 
            {
                setUnbanningId(null);
                setIsLoaded(false);
            })
            .catch((err) => console.error(err));
    }


    if (!isLoaded) return <Loading />;

  return (
    <div style={{ margin: "8px" }}>
        <h1>Memedify's Banned (or previously banned) Users</h1>
        <span>Last update: {latestRefreshDate} (Frankfurt, Germany [Server's Location])</span> <br /> <br /> <br />
        <table>
            <tr>
                <th>#</th>
                <th>Ban ID</th>
                <th>User ID</th>
                <th>Username</th>
                <th>Moderator ID (the person who banned)</th>
                <th>Date of Removal</th>
                <th>Status</th>
                <th>Un-Ban User</th>
            </tr>

            {
                bans.map((value, index) =>
                <tr>
                    <td>{value.id}</td>
                    <td>{value.ban_id}</td>
                    <td>{value.user_id}</td>
                    <td>{value.username}</td>
                    <td>{value.mod_id}</td>
                    <td style={(value.date == "p") ? { color: "red" } : {  }}>{(value.date == "p") ? "Permanent" : value.date}</td>
                    <td style={(value.status == "banned") ? { color: "red", fontWeight: "bold" } : (value.status == "allowed") ? { color: "orange" } : { color: "gray" }}>{value.status}</td>
                    {

                        (value.status != "banned") ? 
                            <td>-</td>
                            :
                            (unabnningId == value.ban_id) ?
                                <td style={{ textAlign: "center" }}><img src={loaderGif} style={{ width: "40px" }} /></td>
                                :
                                <td><button className="ban-btn" onClick={() => unbanUser(value.user_id, value.ban_id)}>Un-Ban</button></td>
                    }
                </tr>)
            }
        </table>
        <ul className="note-list">
          Notes:
          <li>This is the list of users who are not allowed to access Memedify.</li>
          <li>You can ban any user from accessing Memedify in its own separate website. Click on the user's profile, click on the ban button, go through the steps of banning a user, and the user will be denied access.</li>
          <li>A status of <strong style={{ color: "red" }}>banned</strong> states that the user cannot access Memedify at this moment. The status of <strong style={{ color: "orange" }}>allowed</strong> states that the user was unbanned by a moderator, but the user has to take some steps (such as re-agreeing to the Memedify's Terms of Service) to access their account. This status indicates that they did not go through those steps yet, hence, they cannot access Memedify at the moment. The status of <strong>unbanned</strong> states that the user was unbanned by a moderator, went through the steps of accessing their account and now they have access to Memedify.</li>
          <li>You can Un-Ban any user which you think their ban was not right.</li>
          <li>As a respected moderator, the ORDIOUS Team respects your decision. Hence, we expect you to be fair and your actions to be in the cause of justice. Hence, please Un-Ban a permanent or temporary ban if you are completely sure that the ban is unfair. Please don't let users who their only intentions are destorying the platform, to access Memedify. <br />Thank you.</li>
        </ul>
        <br />
        <br />
        <h4>Ban Reasons:</h4>
        <table>
            <tr>
                <th>#</th>
                <th>Ban ID</th>
                <th>Reason</th>
            </tr>

            {
                bans.map((value, index) => 
                <tr>
                    <td>{value.id}</td>
                    <td>{value.ban_id}</td>
                    <td>{value.reason}</td>
                </tr>)
            }
        </table>
    </div>
  );
}

export default MemedifyBans;