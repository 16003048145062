import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";


// pages
import Layout from './pages/Layout';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import Section from './pages/Section';

import MemedifyUserbase from './pages/sections/MemedifyUserbase';
import MemedifyPostsData from './pages/sections/MemedifyPostsData';
import MemedifyModData from './pages/sections/MemedifyModData';
import MemedifyBans from './pages/sections/MemedifyBans';
import MemedifyFindUserById from './pages/sections/MemedifyFindUserById';


export default function App()
{
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="signin" element={<SignIn />} />
          <Route path="section" element={<Section />}>
            <Route path="memedify-userbase" element={<MemedifyUserbase />} />
            <Route path="memedify-posts" element={<MemedifyPostsData />} />
            <Route path="memedify-mods" element={<MemedifyModData />} />
            <Route path="memedify-bans" element={<MemedifyBans />} />
            <Route path="memedify-finduserbyid" element={<MemedifyFindUserById />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

