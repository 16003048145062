import React, { useState, useEffect } from 'react';
import { redirect, useNavigate } from 'react-router-dom';

import Loading from '../../custom-components/Loading';
import axios from 'axios';
import { serverAddress as proxy, serverAddressMemedify as proxyMd, convertDate, splitByCharacter, removeIndexByValue } from '../../custom-modules/customModules';

import loaderGif from '../../Assets/loader.gif';


function MemedifyModData() 
{
    const [isLoaded, setIsLoaded] = useState(false);
    const [mods, setMods] = useState([]);
    const [latestRefreshDate, setLatestRefreshDate] = useState("");

    const [modAddUsernameField, setModAddUsernameField] = useState("");
    const [modAddUsernameError, setModAddUsernameError] = useState("");
    const [isAddingMod, setIsAddingMod] = useState(false);


    const date = new Date();



    if (!isLoaded)
    {
        if (localStorage.getItem("admin-id") != null && localStorage.getItem("admin-id") != "")
        {
            axios.post(`${proxyMd}/getAdminModData`)
            .then((res) => 
            {
                let data = res.data;
                setMods(data);
                let dateInfo = `${convertDate(`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`)} - ${(date.getHours().toString().length == 1) ? `0${date.getHours()}` : date.getHours()}:${(date.getMinutes().toString().length == 1) ? `0${date.getMinutes()}` : date.getMinutes()}`;
                setLatestRefreshDate(dateInfo);
                setIsLoaded(true);
            })
            .catch((err) => console.error(err));
        }
        else redirect("/signin");
    }

    function removeMod(id)
    {
        axios.post(`${proxyMd}/getUserById`, { id: id })
            .then((res) => 
            {
                if (res.data.length == 0) return;
                let data = res.data[0];
                let roles = JSON.parse(data.roles);
                roles = removeIndexByValue(roles, "mod");

                axios.post(`${proxyMd}/modifyUser`, { id: id, action: "modifyRoles", property: JSON.stringify(roles) })
                    .then((res) => 
                    {
                        console.log(res.data);
                        setIsLoaded(false);
                    })
                    .catch((err) => console.error(err));
            })
            .catch((err) => console.error(err));
    }

    function promote()
    {
        setIsAddingMod(true);
        let isValid = true;
        setModAddUsernameError("");

        if (modAddUsernameField.includes("'") || modAddUsernameField.includes("`") || modAddUsernameField.includes('"') || modAddUsernameField.includes("@") || modAddUsernameField.includes("#") || modAddUsernameField.includes("/"))
        {
            isValid = false;
            setModAddUsernameError("Invalid Characters!");
        }

        for (let i = 0; i < mods.length; i++)
        {
            if (modAddUsernameField == mods[i].username)
            {
                setModAddUsernameError("You cannot promote a Moderator!");
                isValid = false;
                break;
            }
        }


        if (!isValid)
        {
            setIsAddingMod(false);
            return;
        }



        axios.post(`${proxyMd}/getUserByUsername`, { username: modAddUsernameField })
            .then((res) => 
            {
                if (res.data.length == 0)
                {
                    setModAddUsernameError("No user exists with the given Username!");
                    setIsAddingMod(false);
                    return;
                }
                const id = res.data[0].user_id;
                let roles = JSON.parse(res.data[0].roles);
                if (roles.length == 0) roles = ["mod"];
                else roles = [...roles, "mod"];

                axios.post(`${proxyMd}/modifyUser`, { id: id, action: "modifyRoles", property: JSON.stringify(roles) })
                .then((res) => 
                {
                    console.log(res.data);
                    setIsLoaded(false);
                    setIsAddingMod(false);
                    setModAddUsernameField("");
                })
                .catch((err) => console.error(err));
            })
            .catch((err) => console.error(err));
    }


    if (!isLoaded) return <Loading />;

  return (
    <div style={{ margin: "8px" }}>
        <h1>Memedify's Moderators</h1>
        <span>Last update: {latestRefreshDate} (Frankfurt, Germany [Server's Location])</span> <br /> <br /> <br />
        <table>
            <tr>
                <th>#</th>
                <th>User ID</th>
                <th>Username</th>
                <th>Email</th>
                <th>Remove Moderation</th>
            </tr>

            {
                mods.map((value, index) =>
                <tr>
                    <td>{value.id}</td>
                    <td>{value.user_id}</td>
                    <td>{value.username}</td>
                    <td>{value.email}</td>
                    <td><button className="ban-btn" onClick={() => removeMod(value.user_id)}>Remove</button></td>
                </tr>)
            }
        </table> <br /> <br />
        <h4>Promote a user to a moderator:</h4>
        <div className="panel" style={{ textAlign: "left" }}>
            <div style={{ textAlign: "center" }}><h3>Fill in the user's info</h3></div>
            <span>Username: </span>
            <div className="field"><strong>@</strong><input className="input-field" value={modAddUsernameField} onInput={(e) => setModAddUsernameField(e.target.value)} maxLength={30} /></div>
            <span className="error">{modAddUsernameError}</span> <br /> <br />
            <div style={{ textAlign: "center" }}>
                {
                    (isAddingMod) ?
                        <img src={loaderGif} style={{ width: "60px" }} />
                        :
                        <button className="btn" onClick={promote}>Promote</button>
                }
            </div>
        </div> <br /> <br /> <br />
    </div>
  )
}

export default MemedifyModData;