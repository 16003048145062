import React from 'react';
import { Outlet } from 'react-router-dom';

function Section() 
{
  return (
    <Outlet />
  );
}

export default Section;