import React, { useState, useEffect } from 'react';
import { json, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from '../../custom-components/Loading';

import { serverAddress as proxy, serverAddressMemedify as proxyMd, convertDate, splitByCharacter } from '../../custom-modules/customModules';
import { redirect } from 'react-router-dom';

import redTrashCan from '../../Assets/icons/trashcan-red.png';
import whiteTrashCan from '../../Assets/icons/trashcan-white.png';


function MemedifyPostsData() 
{
    const [isLoaded, setIsLoaded] = useState(false);
    const [posts, setPosts] = useState([]);
    const [latestRefreshDate, setLatestRefreshDate] = useState("");
    const [removingId, setRemovingId] = useState(null);

    const redirect = useNavigate();
    
    const date = new Date();


    function removePost(id)
    {
        if (removingId != null) return;
        setRemovingId(id);

        axios.post(`${proxyMd}/modifyPost`, { id:id, action: "remove" })
            .then((res) => 
            {
                setRemovingId(null);
                setIsLoaded(false);
            })
            .catch((err) => console.error(err));
    }

    if (!isLoaded)
    {
        if (localStorage.getItem("admin-id") != null && localStorage.getItem("admin-id") != "")
        {
            axios.post(`${proxyMd}/getPosts`, { filter: "none" })
            .then((res) => 
            {
                let data = res.data;
                setPosts(data);
                let dateInfo = `${convertDate(`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`)} - ${(date.getHours().toString().length == 1) ? `0${date.getHours()}` : date.getHours()}:${(date.getMinutes().toString().length == 1) ? `0${date.getMinutes()}` : date.getMinutes()}`;
                setLatestRefreshDate(dateInfo);
                setIsLoaded(true);
            })
            .catch((err) => console.error(err));
        }
        else redirect("/signin");
    }

    if (!isLoaded) return <Loading />;

  return (
    <div style={{ margin: "8px" }}>
        <h1>Memedify Posted posts (Memes):</h1>
        <span>Last update: {latestRefreshDate} (Frankfurt, Germany [Server's Location])</span> <br /> <br /> <br />
        <table>
            <tr>
                <th>#</th>
                <th>Post ID</th>
                <th>Poster User ID</th>
                <th>Title</th>
                <th>Likes</th>
                <th>Media Type</th>
                <th>Tags (Categories)</th>
                <th>Date of Creation</th>
                <th>Nationality</th>
                <th>Status</th>
                <th>Remove</th>
            </tr>

            {
                posts.map((value, index) => 
                <tr>
                    <td>{value.id}</td>
                    <td>{value.post_id}</td>
                    <td>{value.user_id}</td>
                    <td>{(value.title.toString().length == 0) ? "-" : value.title}</td>
                    <td>{value.likes}</td>
                    <td>{value.type}</td>
                    <td>{(JSON.parse(value.tags).length == 0) ? "-" : JSON.parse(value.tags).map((val, ind) => <span className="post-tag">{val}</span>)}</td>
                    <td style={(value.date_of_creation.toString().length == 0 || value.date_of_creation.toString() == "-") ? { color: "gray", fontStyle: "italic" } : {  }}>{(value.date_of_creation.toString().length == 0 || value.date_of_creation.toString() == "-") ? "(posted before this measurement)" : `${splitByCharacter(value.date_of_creation.toString().substring(0, 10).replaceAll("-","/"), "/")[0]}/${splitByCharacter(value.date_of_creation.toString().substring(0, 10).replaceAll("-","/"), "/")[1]}/${splitByCharacter(value.date_of_creation.toString().substring(0, 10).replaceAll("-","/"), "/")[2]} (${convertDate(value.date_of_creation.toString().substring(0, 10).replaceAll("-","/").toString(), false)}) `}</td>
                    <td>{value.nationality}</td>
                    <td style={(value.status == "unverified") ? { color: "gray" } : (value.status == "verified") ? { color: "green" } : (value.status == "deleted") ? { color: "orange" } : { color: "red" }}><strong>{value.status}</strong></td>
                    <td style={(value.status == "removed") ? { } : { textAlign: "center" }}>
                        {
                            (value.status == "removed") ?
                                "-"
                                :
                                <button className="del-btn" onClick={() => removePost(value.post_id)}><img src={redTrashCan} style={{ width: "20px" }} /></button>
                        }
                    </td>
                </tr>)
            }
        </table>
        <ul className="note-list">
          Notes:
          <li>These are all the current posted memes (posts) on Memedify. You are responsible for the shared media on the platfrom. Hence, we expect you to take action if there is any violation. On the other side, we would not want you to remove any post without a valid reason. If there your decision of removing a post falls under Memedify's content policy but you get criticized, in most cases, your decision is respected.</li>
          <li>You can go the Memedify's main feed or use <strong>Search</strong> to view the contents of these posts. The data of the posts are only shown, and the media can be accessed through the website. You can also remove the posts from there.</li>
          <li>The Status shows if a moderator has checked the meme or not. <span style={{ color: "green", fontWeight: "bold" }}>verified</span> means that the post has been verified to fall under Memedify's content policy, <span style={{ color: "gray", fontWeight: "bold" }}>unverified</span> means that a moderator has not verified this post to be obeying the content policy, the <span style={{ color: "orange", fontWeight: "bold" }}>deleted</span> status means that the one who posted the meme deleted their post and they can recover their meme in the website, and the <span style={{ color: "red", fontWeight: "bold" }}>removed</span> status means that the post has been removed by a moderator, and it cannot be recovered.</li>
          <li>Some posts do not have a <strong>Date of Creation.</strong> The reason for this is because these memes (posts) were posted before the implementation of the dating system for the posts.</li>
          <li>Gifs are a type of image. Hence, a type image of a post, can be a gif.</li>
          <li>If you recognize that something is wrong with the data, and you do not have the access to fix the issue, please let a higher-level admin to know about the situation.</li>
        </ul>
    </div>
  );
}

export default MemedifyPostsData;