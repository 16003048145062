import React, { useState, useEffect }  from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './styles/home.scss'
import Loading from '../custom-components/Loading';
import axios from "axios";
import { serverAddress as proxy } from '../custom-modules/customModules';

import memedifyLogo from '../Assets/section-icons/memedify-logo.png';

function Home() 
{
  // use states
  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUser] = useState({});

  const redirect = useNavigate();

  useEffect(() => 
    {
      if (isLoaded == false)
        {
          if (localStorage.getItem("admin-id") != null && localStorage.getItem("admin-id") != "")
          {
            axios.post(`${proxy}/getAdminInfoById`, { id: localStorage.getItem('admin-id') })
              .then((res) => 
                {
                  let data = res.data[0];
                  setUser(data);
                  setIsLoaded(true);
                })
              .catch((err) => console.error(err));
          }
          else redirect("/signin");
        }
    }, [isLoaded]);

    class SectionItem
    {
      constructor (name, image_src, link)
      {
        this.name = name;;
        this.image_src = image_src;
        this.link = link;
      }
    }

    const items_memedify = [
      new SectionItem("Userbase", memedifyLogo, "/section/memedify-userbase"),
      new SectionItem("Posts Data", memedifyLogo, "/section/memedify-posts"),
      new SectionItem("Moderators", memedifyLogo, "/section/memedify-mods"),
      new SectionItem("Bans", memedifyLogo, "/section/memedify-bans"),
      new SectionItem("Find User By Id", memedifyLogo, "/section/memedify-finduserbyid")
    ];

  


  if (!isLoaded) return <Loading />;

  return (
    <div className="home-detail-container">
      <span className="home-section-title">MEMEDIFY MODERATION</span>
      <section className="home-section">
        {
          items_memedify.map((value, index) => 
          <div className="home-section-item" onClick={() => redirect(value.link)}>
            <img src={value.image_src} />
            <span>{value.name}</span>
          </div>)
        }
      </section>
    </div>
  );
}

export default Home;